import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Button, LargeP, grey, primary, darkGrey, xltGrey } from '../elements'
import { sansCondBold } from '../utilities'

const RequestQuote = () => (
  <Request>
    <p>Get in touch today</p>
    <PhoneHome to="tel:+18002237671" rel="nofollow">
      (800) 223-7671
    </PhoneHome>
    <LargeP>– OR –</LargeP>
    <Button to="/contact">Request a Quote</Button>
  </Request>
)

export default RequestQuote

const Request = styled.div`
  margin: 2rem auto;
  padding: 2rem 0 2rem 0;
  text-align: center;
  border-top: 1px solid ${grey};
  border-bottom: 1px solid ${grey};
  width: 100%;
  background: ${xltGrey};
  p {
    margin-bottom: 0.25rem;
  }
`
const PhoneHome = styled(Link)`
  ${sansCondBold}
  font-size: 2rem;
  color: ${darkGrey};
  text-decoration: none;
  transition: 0.3s ease border;
  display: inline-block;
  padding: 0;
  margin-bottom: 1rem;
  &:hover,
  &.active {
    color: ${primary};
  }
`
