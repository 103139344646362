import React from 'react'
import styled from 'styled-components'

import { above, primary, secondary } from '../elements'

const Title = styled.h1`
  font-size: 2.8rem;
  ${above.md`
    font-size: 3.4rem;
  `};
  color: ${primary};
  text-align: center;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${primary};
    &:hover {
      color: ${secondary};
    }
  }
`

const PageTitle = props => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PageTitle
