import React from 'react'
import Layout from '../components/Layout'
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import styled from 'styled-components'
import { primary } from '../elements'

const ConditionalLayout = ({ children, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) =>
      modal ? (
        <React.Fragment>
          <CloseLink to={closeTo}>
            <i className="material-icons">cancel</i> Close
          </CloseLink>
          {children}
        </React.Fragment>
      ) : (
        <Layout {...rest}>{children}</Layout>
      )
    }
  </ModalRoutingContext.Consumer>
)

export default ConditionalLayout

const CloseLink = styled(Link)`
  color: ${primary};
  font-size: 1.25rem;
  text-decoration: none;
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translate(-50%, -50%);
  i {
    font-size: 24px;
  }
`
