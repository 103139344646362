import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import RequestQuote from '../components/RequestQuote'

const BrokerageLogisticsPage = ({ data }) => {
  const postNode = {
    title: `Brokerage & Logistics - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Brokerage & Logistics - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="brokerage-logistics" customTitle />

      <ServiceContainer>
        <PageTitle>Brokerage & Logistics</PageTitle>
        <LargeP>
          Quality no matter the carrier or truck. Customer Service you can trust
          with the flexible capacity you need.
        </LargeP>
        <p>
          Since 1980, our full-service logistics department has a proven track
          record offering the capacity you need along with a knowledgeable team
          of people you can count on. We have developed a strong network of
          qualified and dedicated carriers that are carefully cleared and
          consistently monitored through our carrier compliance department to
          ensure full protection. We track and stay in close communication with
          our partnered carriers and all loads they haul for us.
        </p>

        <RequestQuote />
        <h4>Brokerage & Logistics Benefits</h4>

        <FlexList>
          <FlexItem>Dependable On-time Service</FlexItem>
          <FlexItem>Capacity When You Need It</FlexItem>
          <FlexItem>Competitive Priced Transportation Solutions</FlexItem>
          <FlexItem>On-Call 24/7 Service</FlexItem>
          <FlexItem>Compliance Checks</FlexItem>
          <FlexItem>Route Optimization</FlexItem>
          <FlexItem>Large Network of Carriers</FlexItem>
          <FlexItem>Dedicated Carriers</FlexItem>
          <FlexItem>Equipment Inspection</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default BrokerageLogisticsPage
