import React from 'react'
import styled from 'styled-components'

import { above } from '../elements'

const ServiceContainer = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default ServiceContainer

const Wrapper = styled.div`
  margin: 2rem auto;
  width: 100%;
  max-width: 650px;
  padding: 2rem;
  ${above.lg`
    padding: 3rem;
  `};
  text-align: left;
  flex-grow: 1;
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
`
